<template>
  <div class="park-site wrapper page-wrap">
    <a-spin :spinning="loading">
      <div v-if="site">
        <div class="site-title">{{ site.site_name }}</div>
        <a-row type="flex" justify="space-between">
          <a-col>
            <div class="flex flex-between info">
              <div class="info-title">入驻企业</div>
              <div class="info-content">
                {{ site.enterprise_site?.enterprise_name || "未入驻" }}
              </div>
            </div>
          </a-col>
          <a-col>
            <div class="flex flex-between info">
              <div class="info-title">场地层数</div>
              <div class="info-content">{{ site.site_floor }}</div>
            </div>
          </a-col>
          <a-col>
            <div class="flex flex-between info">
              <div class="info-title">场地面积</div>
              <div class="info-content">{{ site.site_area }}㎡</div>
            </div>
          </a-col>
          <a-col>
            <div class="flex flex-between info">
              <div class="info-title">房屋朝向</div>
              <div class="info-content">{{ site.site_orientation }}</div>
            </div>
          </a-col>
        </a-row>
        <div class="site-img">
          <a-image-preview-group>
            <a-image
              :width="210"
              :height="210"
              :src="item"
              class="img-item"
              v-for="(item, index) in siteImage"
              :key="index"
            />
          </a-image-preview-group>
        </div>
        <div
          v-if="property.length > 0 && site.enterprise_site?.enterprise_name"
          class="mt-30"
        >
          <div class="font-20 font-bold">场地资产</div>
          <div
            v-for="(item, index) in property"
            :key="index"
            class="property-item"
          >
            <div>
              {{ item.property_type_name }}---{{ item.property_name }}---{{
                item.property_num
              }}({{ item.property_remark }})
            </div>
            <div class="site-img">
              <a-image-preview-group>
                <a-image
                  :width="210"
                  :height="210"
                  :src="img"
                  class="img-item"
                  v-for="(img, index2) in toArr(item.property_image)"
                  :key="index2"
                />
              </a-image-preview-group>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { encode, decode } from "js-base64";
import { getSiteData, getPropertyList } from "@/api/main.js";
import { useRoute } from "vue-router";
import { getUserData } from "@/utils/storeData.js";
export default defineComponent({
  name: "ParkSite",
  components: {},
  setup() {
    const route = useRoute();
    const pageData = reactive({
      site: null,
      siteId: null,
      loading: true,
      siteImage: [],
      userData: computed(() => getUserData()),
      property: null
    });
    const getSiteDataFun = _siteId => {
      getSiteData({ site_id: _siteId })
        .then(res => {
          if (res.code == 200) {
            pageData.site = res.data.site;
            if (res.data.site.site_image) {
              pageData.siteImage = res.data.site.site_image.split(",");
            }
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const getPropertyListFun = _siteId => {
      getPropertyList({
        page: 1,
        limit: 15,
        property_type_id: null,
        park_id: pageData.userData?.user_park_id,
        site_id: _siteId
      })
        .then(res => {
          if (res.code == 200) {
            pageData.property = res.data.property;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const toArr = str => {
      if(str){
        return str.split(",")
      }
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/park/site") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.siteId = query[0];
            getSiteDataFun(query[0]);
            getPropertyListFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.site = null;
            message.error("暂无场地信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      toArr
    };
  }
});
</script>
<style lang="less">
.park-site {
  padding-bottom: 20px;
  .site-title {
    position: relative;
    margin-top: 50px;
    padding-left: 20px;
    font-size: 20px;
    color: #212531;
    margin-bottom: 20px;
    &:before {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      background-color: #1276cb;
      border-radius: 9px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .info {
    width: 540px;
    border-bottom: 1px solid #dfdfdf;
    height: 60px;
    .info-title {
      color: #212531;
      opacity: 0.7;
      font-size: 18px;
    }
    .info-content {
      color: #212531;
      font-size: 18px;
    }
  }
  .site-img {
    margin-top: 20px;
    .ant-image {
      margin-top: 20px;
      margin-left: 13.75px;
      margin-right: 13.75px;
      background-color: #1276cb;
      border-radius: 10px;
      .img-item {
        width: 210px;
        height: 210px;
      }
    }

    &:nth-child(5n) {
      margin-right: 0;
    }
    &:nth-child(5n + 1) {
      margin-left: 0;
    }
  }
  .property-item {
    border-bottom: 1px solid #dfdfdf;
    padding: 15px 0;
    font-size: 18px;
  }
}
</style>
